/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import '../../@core/scss/base/core/colors/palette-variables.scss';

@keyframes glow {
    0% {
        box-shadow: 0 0 0 rgba($danger, .2);
    }

    50% {
        box-shadow: 0 0 10px rgba($danger, .6);
    }

    100% {
        box-shadow: 0 0 0 rgba($danger, .2);
    }
}

// Auth pages for mobile and small height & width screen
@media (max-height: 825px) and (max-width: 991.98px) {	
	.auth-wrapper {
	  .auth-bg {
		filter: none;
	  }
	}
}

a:hover {
	text-decoration: underline;
}

b {
	font-weight: 700;

	&.semi {
		font-weight: 600;
	}

	&.margin-reset {
		margin-left: -1px;
	}
}

.alert {
	font-weight: 400;
}

.auth-bg {
	filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.05));
}

.autocomplete-container {
	height: $input-height;
}

.btn-async {
	display: flex;
	align-items: center;
	justify-content: center;

	.btn-async-text {
		display: inherit;
		align-items: inherit;
		justify-content: inherit;
	}

	&.busy {
		.btn-async-text {
			visibility: hidden;
		}

		.btn-async-spinner {
			display: inherit;
			position: absolute;
		}
	}

	.btn-async-spinner {
		display: none;
	}
}

.form-label:not(.form-check-label) {
    font-weight: 600;
}

.img-fluid {
	max-height: 100vh;
}

.pulse {
    animation: glow 1600ms ease alternate;
    outline: none;
}

.pulse-wrapper {
    border-radius: 0.357rem;
}

.react-tel-input {
    .flag-dropdown {
		border: 0px !important;
		border-right: 1px solid #cacaca !important ;
        border-radius: 0 !important;
        background-color: #fff !important; 
		height: calc(100% - 0.325rem);
		margin-top: 2px;
		margin-left: 2px;
    }

    input.form-control {
        height: $input-height;
        font-family: $font-family-sans-serif;
        font-size: $font-size-base;
        width: 100%;

        &:focus {
            border-color: map-get($primary-color, 'base');
            & + .flag-dropdown {
                border-color: map-get($primary-color, 'base');
            }
        }
    }   
}

.strength-bar {
    p {
        font-size: 12px !important;
    }

    > div {
        > div {
            height: 4px !important;
            border-radius: 5px !important;
        }
    }
}

.wetu-logo {
	height: 80px;
}

.wrapper-select-type {
    display: flex;
	flex-direction: column;

	.card {
		background-color: $gray-50;
		border: 1px solid map-get($secondary-color, 'lighten-4');
		box-shadow: none;
		cursor: pointer;

		&:hover, &.selected {
			border: 1px solid map-get($primary-color, 'base');
			background-color: rgba(map-get($primary-color, 'base'), .15);

			h3, svg {
				color: map-get($primary-color, 'base');
			}
		}

		&:last-child {
			margin-bottom: 0 !important;
		}

        svg {
            height: 24;
            width: 24;
        }
	}
}

.color-info {
	color: $info;
}

.input-alert-icon {
	right: 0.725rem;
	padding-left: 0;
	bottom: 10px;
}