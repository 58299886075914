//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================
$primary: #6CB741;
$danger: #DB5461;
$info: #1F8BFF;
$warning: #F18F01;
$success: #04A777;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer,
  2: $spacer * 2,
  4: $spacer * 4,
  10: $spacer * 10,
  25: $spacer * .25,
  50: $spacer * .5,
  150: $spacer * 1.5,
);